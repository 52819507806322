import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import falconBoosters from '../images/falcon-booster-raw.png';
import starryNight from '../images/starry-night-raw.png';
import blended from '../images/blended-raw.png';

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={['Pictures', 'Paintings', 'Images', 'Machine Learning']} title="Home" />

      <section className="text-center">
        <h1 className="inline-block mb-2 text-4xl font-bold text-white">
          Welcome to TensorFlow Art
        </h1>

        <p className="leading-loose text-white">
          We create custom AI powered art from your images.
        </p>
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-12 mt-6 col-gap-4 row-gap-2">
        <div className="col-span-1 lg:col-span-7">
          <h3 className="text-xl text-white font-bold text-center">You Supply These</h3>
        </div>
        <div className="col-span-1" />
        <div className="col-span-1 lg:col-span-4">
          <h3 className="text-xl text-white font-bold text-center hidden lg:block">
            We Create This
          </h3>
        </div>
        <div className="col-span-1 lg:col-span-3 flex items-center justify-center">
          <img src={falconBoosters} alt="SpaceX Falcon Boosters landing" />
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-white text-4xl font-bold ">+</p>
        </div>
        <div className="col-span-1 lg:col-span-3 flex items-center justify-center">
          <img src={starryNight} alt="Starry Night painting by Vincent van Gogh" />
        </div>
        <div className="col-span-1 flex items-center justify-center">
          <p className="text-white text-4xl font-bold hidden lg:block">=</p>
          <p className="text-white text-2xl font-bold lg:hidden">We Create This</p>
        </div>
        <div className="col-span-1 lg:col-span-4 flex items-center justify-center">
          <img
            src={blended}
            alt="Combined print of the Falcon Boosters and Starry night created by our machine learning model"
          />
        </div>
        <div className="col-span 1 lg:col-span-12 my-5 text-center">
          <p className="leading-loose text-white">
            Let us create a customized print for you to use anywhere you want. T-shirts, caps,
            prints and more.
            <Link to="try-it-now" className="font-bold text-blue-200">
              {' '}
              Sign up
            </Link>{' '}
            and get notified when we are accepting orders.
          </p>
        </div>
        <div className="col-span 1 lg:col-span-12 my-8 text-center">
          <Link
            to="/try-it-now"
            className="text-white bg-transparent hover:bg-blue-300 text-blue-300 font-semibold hover:text-blue-900 py-4 px-8 border border-blue-300 hover:border-transparent rounded"
          >
            Try It Now
          </Link>
        </div>
        <div className="col-span 1 lg:col-span-12 my-3 text-center">
          <Link
            to="/more-examples"
            className="text-white bg-transparent hover:bg-blue-300 text-blue-300 font-semibold hover:text-blue-900 py-4 px-8 border border-blue-300 hover:border-transparent rounded"
          >
            More Examples
          </Link>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
